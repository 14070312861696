<template>
  <div class="container px-3 pb-12 mx-auto">
    <router-link to="/swot/archived/" class="px-3 py-2 text-white bg-blue-400 rounded text-md"
      ><span class="text-md">&#10094;&#10094;</span>&nbsp;&nbsp; All Archived Clients</router-link
    >
    <section class="flex flex-wrap mt-8 mb-2">
      <div class="flex justify-between w-full mb-10">
        <h1 class="flex items-center mr-16 text-4xl font-bold text-blue-900 uppercase">
          {{ client_id }}
          <!-- <a
            
            class="copy-all-icon"
          >
            <svg
              class="w-10 h-10 ml-2 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              />
            </svg>
          </a> -->
        </h1>
        <div class="is-pulled-right">
          <button class="flex items-center px-3 py-2 text-green-100 bg-green-500 rounded" v-on:click="unArchiveClient">
            Make Channel Active
          </button>
        </div>
      </div>

      <SwotSection
        v-for="section in sections"
        :key="section.collection"
        :title="section.title"
        :collection="section.collection"
        :clientId="client_id"
      />
    </section>
  </div>
</template>

<script>
  import { repgen } from "@/firebase";
  import SwotSection from "@/components/swot/SwotSection.vue";
import { doc, updateDoc } from "@firebase/firestore";

  export default {
    components: {
      SwotSection,
    },
    data() {
      return {
        sections: [
          {
            title: "PARKING LOT",
            collection: "parking_lot",
          },
          {
            title: "EXEC SUMMARY",
            collection: "exec_summary",
          },
          {
            title: "STRENGTHS",
            collection: "strengths",
          },
          {
            title: "WEAKNESSES",
            collection: "weaknesses",
          },
          {
            title: "OPPORTUNITIES",
            collection: "opportunities",
          },
          {
            title: "THREATS",
            collection: "threats",
          },
          {
            title: "RECOMMENDATIONS",
            collection: "recommendations",
          },
          {
            title: "INFO",
            collection: "info",
          },
          {
            title: "QUOTES",
            collection: "quotes",
          },
          {
            title: "ADD",
            collection: "adds",
          },
        ],
        allSWOT: "",
        parkingLots: [],
        client_id: this.$route.params.clientID,
        archived: false,
      };
    },
    created() {},
    methods: {
      unArchiveClient: function() {
        updateDoc(doc(repgen, "swots", this.$route.params.clientID), {
          archive: false
        })
        this.archived = false;
        this.$router.push("/");
      },
      closeNotification: function() {
        this.message = "";
      },
      handleCopyStatus(status) {
        this.copySucceeded = status;
      },
      //   showAlert() {
      //     var i;

      //     this.allStrengths = "";
      //     for (i = 0; i < this.strengths.length; i++) {
      //       this.allStrengths += this.strengths[i].text + "\n\n";
      //     }
      //     this.allSWOT = "STRENGTHS\n\n" + this.allStrengths + "\n\n";

      //     this.allWeaknesses = "";
      //     for (i = 0; i < this.weaknesses.length; i++) {
      //       this.allWeaknesses += this.weaknesses[i].text + "\n\n";
      //     }
      //     this.allSWOT += "WEAKNESSES\n\n" + this.allWeaknesses + "\n\n";

      //     this.allOpportunities = "";
      //     for (i = 0; i < this.opportunities.length; i++) {
      //       this.allOpportunities += this.opportunities[i].text + "\n\n";
      //     }
      //     this.allSWOT += "OPPORTUNITIES\n\n" + this.allOpportunities + "\n\n";

      //     this.allThreats = "";
      //     for (i = 0; i < this.threats.length; i++) {
      //       this.allThreats += this.threats[i].text + "\n\n";
      //     }
      //     this.allSWOT += "THREATS\n\n" + this.allThreats + "\n\n";

      //     this.allInfo = "";
      //     for (i = 0; i < this.info.length; i++) {
      //       this.allInfo += this.info[i].text + "\n\n";
      //     }
      //     this.allSWOT += "INFO\n\n" + this.allInfo + "\n\n";

      //     this.allQuotes = "";
      //     for (i = 0; i < this.quotes.length; i++) {
      //       this.allQuotes += this.quotes[i].text + "\n\n";
      //     }
      //     this.allSWOT += "QUOTES\n\n" + this.allQuotes + "\n\n";

      //     this.allAdds = "";
      //     for (i = 0; i < this.adds.length; i++) {
      //       this.allAdds += this.adds[i].text + "\n\n";
      //     }
      //     this.allSWOT += "NOTES\n\n" + this.allAdds + "\n\n";
      //   },
    },
  };
</script>

<style></style>
